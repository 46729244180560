.app{
    height: 100vh;

    .secciones{
        width: 100%;
        height: calc(100vh - 70px);
        background-color: white;
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory; 
        scrollbar-width: none; //sacar la barra en firefox
        &::-webkit-scrollbar{ //sacar la barra en todos los navegadores
            display: none;
        }

        > *{
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
        }
    }
}
