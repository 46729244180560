@import "../../global.scss";

/**{
  outline:1px solid red;
}*/

.contact {
  background-color: white;
  display: flex;


   @include mobile {
        display: block;
      }
  

  .left {
    flex: 1;
    overflow: hidden;

    img {
      height: 100%;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;
    }

    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      
     
      
      label {
        display: block;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
        color: #1f1f1f;
      }

      input {
        width: 300px;
        height: 30px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 20px;
          margin-bottom: 10px;
        }
        @include tablet {
          width: 200px;
          height: 20px;
          margin-bottom: 10px;
        }
      }

      .error {
        //display: block;
        color: #e92b2d;
        font-weight: 600;
        font-size: 12px;

        @include tablet {
          position: absolute;
          font-size: 12px;
          vertical-align: middle;
          height: 20px;
        }

        @include mobile{
          position:unset;
        }

      }
  
      .ingresaMensaje{
        height: 20px;
        text-align: start;
        position: absolute;
      }

      textarea {
        width: 300px;
        height: 200px;
        font-size: 14px;

        @include tablet {
          width: 200px;
          height: 100px;
          
        }
        @include mobile {
          width: 200px;
          height: 90px;
        }
        
      }

      button {
        width: 150px;
        height: 30px;
        color: white;
        background-color: crimson;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;

        @include tablet {

      
        }

        &:focus {
          outline: none;
        }
      }

      span {
        color: green;
        
      }
    }
  }
}
.centrar{
  display: flex;
  justify-content: center;

}

.alert-shown{
  opacity: 1;
  transition: linear 0.2s;
}

.alert-hidden{
  opacity: 0;
  transition: all 2500ms linear 0.4s;
}

.telefonoIcon {
  font-size: 16px;
  margin-right: 5px ;
  width: 30px !important;
  margin-top: 0px;

}
.numero{
  color:#1f1f1f !important;
  margin-top: 2px;
}

.E-mail{
  @include tablet {
    display: block;
    overflow: hidden; 
  }
}

.mensaje{
  @include tablet {
    display: block;
    height: 140px;
    overflow: hidden; 

  }
}

.mensaje2{
  @include tablet {
    margin-top: 20px;
    height: 180px;
  }
}

.tel{
  height: 100%;
  overflow: hidden; 
  margin-left: -45px;
}

.gatito{
  height: 24px;
  width: 24px;
}
/*Redes sociales*/
/*a { 
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; 
  color: #000;
}*/

.redes{
  overflow: hidden; 

}
.social { 
  list-style:none; 
  margin-top:2em;
  width: 100%;


  margin-left: 5px;
  
  
  
}
.social li { 
  display: flex;
  float:left; 
  justify-content: center;
  margin: 0 40px 0 0;
  width:64px; 
  margin-bottom: 30px; 

  
  
  @include mobile {
    float:left; 
    margin-left: -5px;
    width:25px; 
    margin-bottom: 30px; 
    display: flex;
    justify-content: center;
  }

  @include tablet {
    display: flex;
    justify-content: center;
  }
 

}

.correo22{
  height: 40px;
  width: 40px;
}

/*
.social li a { 
  display:block; 
  width:64px; 
  height:64px; 
  position:relative; 
}
.social li a strong { 
  position: absolute; 
  left: 0px; 
  top: 10px;
}*/
/*
li.fb { 
  background:url("https://3.bp.blogspot.com/-GBtZIVeafFU/UZ1FYjGvufI/AAAAAAAACII/HGNZIJO1oT0/s1600/facebook-64.png") #3B5998; 
}
li.twitter { 
  background:url("https://2.bp.blogspot.com/-ks0tKggjK-w/UZ1FYhudbWI/AAAAAAAACIM/smFlLtwfbxk/s1600/twitter-64.png") #0092cb; 
}*/
.social:hover li { 
  opacity:0.3; 
}
.social li { 
  transition-property: 
  opacity; 
  transition-duration: 500ms; 
}
.social li a strong { 
  opacity:0; 
  transition-property: opacity, bottom; 
  transition-duration: 250ms;
}
.social li:hover { 
  opacity:1; 
}
/*
.social li:hover a strong { 
  opacity:1; 
  top:-23px; 
}*/
