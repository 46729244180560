$mainColor: #15023a;

$width:768px;

$height:715px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
}

@mixin tablet {
    @media (max-height: #{$height}) {
        @content
      }
}
