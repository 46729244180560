@import "../../global.scss";

.fondo{
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    
}


.card{
    //position: absolute; 
    
    /*top: 25%;
    
    right: 25%;*/
   

    margin-top: -70px;
    width: 70%;
    height: 70%;
    border-radius: 30px;
   
    background-color: #fafbfd;

    /*
    -webkit-box-shadow: 0px 15px 30px 40px rgba(211,211,211,0.8);
    -moz-box-shadow: 0px 15px 30px 40px rgba(211,211,211,0.8);
    box-shadow: 0px 15px 30px 40px rgba(211,211,211,0.8);
*/

    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border: 1px solid #ccc;

    overflow:hidden;

    
    @include mobile{
        overflow: auto;
    }
    
}

.centrarC{
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    height: 110px;
    position:relative;
    display:flex;
    justify-content: center;
    
    

    .circulo {
 
        width: 100px;
        height: 100px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        border: 3px solid #D3D3D3;
        overflow: hidden;
        background-color:  #d6eaf8 ;
        
        .foto{
        //width: 200px;
        //height: 200px;
        //margin-top: -40px;
       // margin-left: -50px;
       // -moz-border-radius: 50%;
      //  -webkit-border-radius: 50%;
       // border-radius: 50%;
      //  overflow: hidden;
        
        }
        
    }

}

.linea{
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 30px;
 }
 
 .parrafo{
    margin-left: 5%;
    margin-right: 5%;
    font-size: 20px;
 
    @include mobile{
     font-size: small;
     }

     @include tablet{
        font-size: small;
    }
    
 }

.contenedor{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @include tablet{
        //opacity: 0;
    }

    
}

.contenedorImg{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow:hidden;
}

.web{
    width: 60px;
    height: 60px;
    
}
.api{
    width: 100px;
    height: 100px;
    margin-top: -18px ;
}
.req{
    width: 80px;
    height: 60px;
}

.apiP{
    margin-top: -23px;
}

/*
.centrarC2{
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
    position:relative;
    display:flex;

    flex-direction: row wrap;
/*
    @include mobile{
        position: unset;
       //display: unset;
       justify-content: unset;
       
       
    }
}




.api{
    position: absolute;
    width: 100px;
    height: 100px;
    margin-left: 47%;
    /*
    @include mobile{
      margin-left: 45%;
      width: 60px;
      height: 60px;
      margin-top: 5px;
    }
    
}

.pa{
    //position: relative;
    margin-top: 80px;
    //margin-left: 600px;
    margin-left: 45%;
    width: 100%;
    color: #675f58 ;
    font-weight: bold;
    //display:flex;
    /*
    @include mobile{
        font-size: small;
        width: 60px;
        margin-left: 45%;
        margin-top: -69px;
        text-align: center;
    }
}



.check{
    width: 80px;
    height: 60px;
    margin-left: 50%;
    position: absolute;
    margin-top: 20px;
    display:flex;
    /*
    @include mobile{
        margin-left: 70%;
        width: 60px;
        height: 30px;
      }
}

.pa2{
    position: relative;
    margin-top: -120px;
    color: #675f58 ;
    font-weight: bold;
    margin-left: 335px;
    display:flex;/*
    @include mobile{
       margin-left: 66%;
       font-size: small;
       width: 100px;
       margin-top: -69px;
       text-align: center;
    }
}

.bd{
    width: 60px;
    height: 60px;
    margin-left: -50%;
    position: absolute;
    margin-top: 20px;
    display:flex;
/*
    @include mobile{
        margin-left: 25%;
        width: 30px;
        height: 30px;
      }
}

.pa3{
    position: relative;
    margin-top: -120px;
    color: #675f58 ;
    font-weight: bold;
    margin-left: -335px;
    display:flex;
/*
    @include mobile{
        margin-left: 22%;
        font-size: small;
        width: 60px;
        margin-top: -69px;
        text-align: center;
     }
 }

 
.wave{
    width: 3000%;
    height: 50%;
    position: relative;
    background-image: url(../../assets/wave.svg);
    
  
    
}

.w1{
    animation:w1 7s linear infinite;
}

@keyframes w1 {
    0%{
        margin-left: 0;
    }
    100%{
        margin-left: -100%;
    }
}
*/