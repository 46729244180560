@import "../../global.scss";
//gris #212933
.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  

  h1 {
    font-size: 50px;
    overflow: hidden;
    

    @include mobile{
      font-size: 20px;
      height: 50px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;
    overflow: hidden; 
    height: 31px;
    

    @include mobile{
      margin: 10px 0;
      height: 20%;
      flex-wrap: wrap;
      overflow: hidden; 
      justify-content: center;
    }

    @include tablet{
      //height: 39px;
      
    }
  }

  .container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    

    @include mobile{
      width: 100%;
    }

    

    .item {
      width: 420px;
      height: 270px;
      border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;
      overflow: hidden;


      @include tablet{
        width: 330px;
        height: 190px;
        overflow: hidden;
      }

      @include mobile{
        width: 330px;
        height: 200px;
      }

      

      .mouse{
        display: flex;
        position: absolute;
        bottom: 0px;
     
      // margin-bottom: 10px;
      //  background-color:rgba(0, 0, 0, 0.2);
        //border-radius: 25px;
        z-index: 1;
        
      }

      h3 {
        display: flex;
        position: absolute;
        flex-direction: column;
        font-size: 20px;
        justify-content: center;
        
        .presentacionApp{
            display: flex;
            width: 420px;
            //height: 270px;
            border-radius: 20px;
            align-items: center;
            justify-content: center;
           

            @include mobile{
              //width: 130px;
             //height: 100px;
            }
        }
        h2{
          margin-top: 10px;
          margin-left: 90px;
          width: 250px;
          font-size: small;
        }

        .limpio{
          display: flex;
          align-items: center;
          justify-content: center;
          color: inherit;
          text-decoration: inherit;
          margin-top: 110px ;
          font-size: medium;
          text-decoration: underline;

          @include tablet{
            margin-top: 50px;
          }

          @include mobile{
            margin-top: 30px;
          }
        }

        .limpio:hover {
            color: lightblue;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
        .mouse{
          z-index: -1;
        }
        
      }
    }
  }
}

