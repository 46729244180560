@import "../../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Open+Sans:wght@600;800&display=swap');


.barraInicio {
  width: 100%;
  height: 70px;
  background-color: white;
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;
  overflow: hidden;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .logo {
        position:relative;
        display: inline-block;
        text-align: center;

        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
        overflow: hidden;
      }

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 30px;
        
        @include mobile{
          display: none;
        }

       

        span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .right {
      margin-top: -20px;
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          transform-origin: left;
          transition: all 1s ease;
        }
      }
    }
  }

  &.active {
    background-color: $mainColor;
    color: white;

    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.vacio{
 // width: 0px;
  //height: 0px;

  width: 60px;
  height: 60px;
  -webkit-animation: 3s void linear infinite;
  animation: 2s void linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.nombre{
  position: absolute;
  top: 13px;
  left: 11px;

  font-size: 23px;
  font-family: 'Open Sans', sans-serif;

  color:white;

  -webkit-text-stroke: 1px black;
}

.cv{
  font-size: 20px;
  margin-top: -20px;
  margin-left: -5%;
  @include mobile{  
      margin-left: -15%;
  }
  @include tablet{
    margin-left: -90px;
  }
}


/*Solo rotar*/
@keyframes rotate {from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}}
@-webkit-keyframes rotate {from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}}




/*Rotar y pulso*/

@keyframes void {
  0%{
    transform: rotate(180deg);
  }
  50%{
      transform: rotate(270deg) ;
  }
  52%{
    transform: rotate(270deg) scale(1.2);
  }
  63%{
    transform: rotate(300deg);
  }
  65%{
    transform: rotate(300deg) scale(1);
  }
 
  
  100%{
      transform: rotate(360deg) ;
  }


}