@import "../../global.scss";

.menu {
  width: 300px;
  height: 100vh;
  background-color: $mainColor;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;

  &.active {
    right: 0;
  }
  .prueba{
    position: fixed;
    display: flex;
    width: 210px;
    position: absolute;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 30px;
      font-weight: 300;
      color: white;
      width: 100%;
  
      li {
        margin-bottom: 25px;
        a {
          font-size: inherit;
          color: inherit;
          text-decoration: none;
        }
        &:hover {
          font-weight: 500;
        }
  
        
      }
    }
  }

  .prueba2{
    width: 390px;
    position: absolute;

    

    ul {
      margin: 500px 90px 50px 50px;
      list-style: none;
      font-size: 15px;
      font-weight: 300;
      color: white;
      width: 60%;
  
      li {
        margin-bottom: 15px;
        a {
          font-size: inherit;
          color: inherit;
          text-decoration: none;
        }

        .icon {
          font-size: 17px;
          margin-right: 5px;
          margin-bottom: -3px;
        }
/*
        &:hover {
          font-weight: 500;
        }*/
      }
    }
  }


  
}