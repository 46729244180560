@import "../../global.scss";


.skills{
    
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    


    /* centrar todo al medio*/

    justify-content: flex-start;
  
    @include tablet{
        justify-content: center;
    }

    
}

.titulo{
    display: flex;
    flex-direction: column;
    align-items: center; 
    font-weight: 500;
    font-size: 70px;
    margin-top: 2em;
    margin-bottom: 8px;

    @include tablet{
        margin-top: 0;
    }
    
}
/*
.cont{
    @include mobile{
       display: flex;
       align-items: center;
       flex-wrap: wrap;
       width: 100%;
      }

}*/

.subTitulo{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 360;
    

    @include mobile{
        margin-left: 40px;
        margin-right: 40px;
        font-size: small;
      }
   
}

.subTitulo2{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 360;
    margin-bottom: 2%;
    
    @include mobile{
        
        margin-left: 40px;
        margin-right: 40px;
        font-size: small;
      }
   
}

.barra { 
    display: flex;
    height: 30px;
    position: relative;
    width: 900px;
    background: #ddd;
    border-radius: 5px;
    overflow: hidden;    
    margin-bottom: 15px;

    @include mobile{
        width: 300px;
    }

    h1{
        /*Opcion a la derecha */
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        font-weight: 300;
        
        

        /* opcion en barra 
        width: 41px; 
        display: inline;
        height: 100%;
        font-size: 15px;
        font-weight: 200;
        line-height: 20px;       
        
        border: cyan 5px solid;
        background-color: cyan;
        */
        
        }

        span {
            display: block;
            height: 100%;
            position: relative;
            overflow: hidden; 
            animation:prog 2s linear;
          
          
            
            
            
            
          
            h3{
              text-align: center;
              background: rgba(0, 0, 0, 0.15);
              padding-right: 10px;
              padding-left: 10px;
              display:block;
              overflow: hidden;
              line-height: 30px;
              width: 100px;
            
              @include mobile{
                  width: 50px;
                  font-size: small;
              }
              
            }
        }
}

.habilidad1{
    width: 90%;
    background:#AED6F1;

    @include mobile{
        width:90% ;
    }
}

.habilidad2{
    width: 80% ;
    background:#A3E4D7;

    @include mobile{
        width:80% ;
    }
}

.habilidad3{
    width: 70% ;
    background:#FAD7A0;

    @include mobile{
        width:75% ;
    }
}

.habilidad4{
    width: 65% ;
    background:#EDBB99;

    @include mobile{
        width:70% ;
    }
}

.habilidad5{
    width:60% ;
    background:#B39DDB;

    @include mobile{
        width:65% ;
    }
}

.habilidad6{
    width: 50% ;
    background:#FFAB91;

    @include mobile{
        width:60% ;
    }
}




@keyframes prog {
    from {
        width:0%;
    }
    to {}
}

